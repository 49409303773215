import { Title } from '@soluto-private/vds-typography';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { PageLayout } from '../../core-ui';
import { RoutePath } from '../../navigation';
import { Breadcrumbs } from '../../navigation/components';
import { VideoSection } from './VideoSection';

const StyledHeader = styled.div`
    margin: 0 0 0.5rem 2rem;
`;

const VideoHeader: FC = () => {
    const { t } = useTranslation();

    return (
        <StyledHeader>
            <Breadcrumbs
                breadcrumbs={[
                    {
                        text: t('securityCenter'),
                        path: RoutePath.SecurityCenter,
                    },
                    { text: t('page.videos.title') },
                ]}
                margin="0 0 1.5rem 0"
            />
            <Title size="large">{t('page.videos.title')}</Title>
        </StyledHeader>
    );
};

const VideoPage: FC = () => (
    <PageLayout
        pageName="VideoPage"
        header={<VideoHeader />}
        body={<VideoSection />}
        margin="1.5rem 0"
    />
);

export default VideoPage;
