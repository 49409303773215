import { Title } from '@soluto-private/vds-typography';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button } from '../core-ui';
import { PageLayout } from '../core-ui/PageLayout/PageLayout';
import { useErrorContext } from './useErrorContext';

const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
`;
const ButtonWithMargin = styled(Button)`
    margin-bottom: 1rem;
`;

const ErrorHeader: FC = () => {
    const { t } = useTranslation();

    return <Title size="2XLarge">{t('page.error.title')}</Title>;
};

const ErrorBody: FC = () => {
    const { t } = useTranslation();

    const { setError } = useErrorContext();

    useEffect(() => {
        // clear error state upon visiting error page
        setError(undefined);
    }, [setError]);

    return (
        <>
            <ButtonContainer>
                <ButtonWithMargin
                    actionId="Refresh"
                    href={process.env.REACT_APP_REFRESH_SMART_LINK}
                >
                    {t('page.error.refresh')}
                </ButtonWithMargin>
                <Button
                    secondary
                    actionId="GoBack"
                    href={process.env.REACT_APP_EXIT_SMART_LINK}
                >
                    {t('page.error.goBack')}
                </Button>
            </ButtonContainer>
        </>
    );
};

export const ErrorPage: FC = () => (
    <PageLayout
        pageName="ErrorPage"
        header={<ErrorHeader />}
        body={<ErrorBody />}
        margin="4rem 2rem"
    />
);
