import { useEffect } from 'react';

import i18n from '../i18n';
import { getContentfulLocale, LanguageContext } from './LanguageContext';

export const withLanguageProvider =
    <P extends {}>(Wrapped: React.ComponentType<P>): React.FC<P> =>
    (props: P) => {
        // Default language detection is handled by i18next
        // Allow language override if query parameter is present
        useEffect(() => {
            const getQueryParam = (param: string): string | null => {
                const urlParams = new URLSearchParams(window.location.search);
                return urlParams.get(param);
            };

            const lang = getQueryParam('lang');
            if (lang) {
                i18n.changeLanguage(lang);
            }
        }, []);

        // Use resolved language to make sure we stick to supported locales only
        return (
            <LanguageContext.Provider
                value={getContentfulLocale(i18n.resolvedLanguage)}
            >
                <Wrapped {...props} />
            </LanguageContext.Provider>
        );
    };
