import { Body, Micro } from '@soluto-private/vds-typography';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { MarkAsHelpful } from '../../tips/components/MarkAsHelpful';

export type DidYouKnowTipsCardProps = {
    sysId: string;
    tipId: string;
    taskDescription: string;
};

const DidYouKnowTipsCardContainer = styled.div`
    height: 11rem;
    padding: 1.5rem 2rem;
`;

const BodyContainer = styled.div`
    margin-top: 0.5rem;
`;

export const DidYouKnowTipsCard: FC<DidYouKnowTipsCardProps> = (props) => {
    const { t } = useTranslation();

    return (
        <DidYouKnowTipsCardContainer>
            <Micro>{t('section.didYouKnow.title')}</Micro>
            <BodyContainer>
                <Body bold>{props.taskDescription}</Body>
            </BodyContainer>
            <MarkAsHelpful
                id={`tip-${props.tipId}`}
                extraData={{ TipId: props.tipId, TipSysId: props.sysId }}
                text={t('section.didYouKnow.isThisHelpful')}
            />
        </DidYouKnowTipsCardContainer>
    );
};
