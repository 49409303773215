import { Line } from '@soluto-private/vds-lines';
import { Loader } from '@soluto-private/vds-loaders';
import { Title } from '@soluto-private/vds-typography';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { CategoryTags } from '../../../categories/config';
import { LinkList, LinkListItemProps, PageLayout } from '../../../core-ui';
import { RoutePath } from '../../../navigation';
import { Article, ArticleEntry } from '../../Article';
import { useArticles } from '../../hooks';
import { formatArticles } from '../utils';

const StyledLine = styled(Line)`
    margin: 0 0 1.5em 0;
`;

const ArticlesHeader: FC = () => {
    const { t } = useTranslation();

    return <Title size="large">{t('page.articles.title')}</Title>;
};

const ArticlesBody: FC = () => {
    const { value, loading } = useArticles<Article>({
        additionalParams: {
            'metadata.tags.sys.id[in]': [CategoryTags.securityAdvisor],
        },
    });
    const [articles, setArticles] = useState<ArticleEntry[]>();
    const [formattedArticles, setFormattedArticles] =
        useState<LinkListItemProps[]>();

    useEffect(() => {
        setArticles(value);
    }, [value, loading]);

    useEffect(() => {
        setFormattedArticles(formatArticles(articles));
    }, [articles]);

    // TODO: articles translations are from Contentful

    return (
        <div>
            <StyledLine />
            <Loader active={loading} />
            <LinkList items={formattedArticles ?? []} />
        </div>
    );
};

export const ArticlesPage: FC = () => {
    const { t } = useTranslation();

    return (
        <PageLayout
            pageName="ArticlesPage"
            breadcrumbs={[
                { text: t('securityAdvisor'), path: RoutePath.SecurityCenter },
                { text: t('page.articles.title') },
            ]}
            header={<ArticlesHeader />}
            body={<ArticlesBody />}
        />
    );
};
