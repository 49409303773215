import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Document } from '@contentful/rich-text-types';
import { Body, Title } from '@soluto-private/vds-typography';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button, theme } from '../../core-ui';
import { ExpertChatCTA } from '../../ctas';
import { DailytaskEntry } from '../../types';

export type Props = {
    tip: DailytaskEntry;
    onClick: () => void;
};

const WrappedButton = styled(Button)`
    margin-top: 2rem;
`;

const Time = styled.div`
    margin-top: 0.5rem;
`;

const StyledExpertChatContainer = styled.div`
    margin-top: 1rem;
`;

export const UncompletedTip: FC<Props> = ({
    tip: {
        fields: { title, estimatedTime, taskdescription, id },
    },
    onClick,
}) => {
    const { t } = useTranslation();

    // TODO: tip translations are from Contentful

    return (
        <>
            <Title size="medium">{title}</Title>
            <Time>
                <Body color={theme.colors.greys.dark}>{estimatedTime}</Body>
            </Time>
            <Body>
                {taskdescription &&
                    documentToReactComponents(taskdescription as Document)}
            </Body>
            <StyledExpertChatContainer>
                <ExpertChatCTA />
            </StyledExpertChatContainer>
            <WrappedButton
                onClick={onClick}
                actionId="TipCompleted"
                extraData={{ TipId: id }}
            >
                {t('page.todaysTip.markAsComplete')}
            </WrappedButton>
        </>
    );
};
