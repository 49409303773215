import { Body, Title } from '@soluto-private/vds-typography';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ArticleSection } from '../../articles/components';
import { CategoryTags } from '../../categories/config';
import { CategoryContainer } from './Categories';

const StyledTitleContainer = styled.div`
    margin: 1.5rem 2rem 0 2rem;
`;

const PopularTextContainer = styled.div`
    margin: 2.5rem 0 0 2rem;
`;

export const PopularArticles: FC = () => {
    const { t } = useTranslation();

    return (
        <>
            <StyledTitleContainer>
                <Title size="medium">{t('section.articles.title')}</Title>
            </StyledTitleContainer>
            <CategoryContainer />
            <PopularTextContainer>
                <Body bold>{t('section.articles.body')}</Body>
            </PopularTextContainer>
            <ArticleSection
                tags={[CategoryTags.popular, CategoryTags.securityAdvisor]}
                limit={3}
            />
        </>
    );
};
