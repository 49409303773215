import { EntryCollection, EntrySkeletonType } from 'contentful';
import { useContentfulProductClient } from './useContentfulProductClient';
import { useAsyncRetry } from 'react-use';
import { useErrorHandling } from '../../error-handling';
import { ContentParams } from '../createUseContentfulContent';
import { useLanguage } from '../../i18n';

export function useProductContent<T extends EntrySkeletonType>({
    contentType,
    limit,
    additionalParams,
    isRequiredContent = true,
}: ContentParams) {
    const client = useContentfulProductClient();
    const locale = useLanguage();
    
    const state = useAsyncRetry(async () => {
        const entries: EntryCollection<
            T,
            'WITHOUT_UNRESOLVABLE_LINKS',
            string
        > = await client.getEntries<T, string>({
            content_type: contentType,
            limit,
            ...additionalParams,
            locale,
        });
        return entries.items;
    }, [client]);

    useErrorHandling({
        state,
        message: `Failed to fetch ${contentType} from contentful`,
        showErrorPage: isRequiredContent,
    });

    return state;
}
