import { Loader } from '@soluto-private/vds-loaders';
import { Title } from '@soluto-private/vds-typography';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const LoadingContainer = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

const TitleContainer = styled.div`
    text-align: center;
    margin: 10.25rem 0rem 2rem 0rem;
`;

const StyledLoader = styled(Loader)`
    background-color: transparent;
    height: 2.5rem;
    width: 2.5rem;
    position: relative;
`;

export const Loading: FC = () => {
    const { t } = useTranslation();

    return (
        <LoadingContainer>
            <TitleContainer>
                <Title size="XLarge">{t('securityCenter')}</Title>
            </TitleContainer>
            <StyledLoader active={true} />
        </LoadingContainer>
    );
};
