import Icon from '@soluto-private/vds-icons';
import { Line } from '@soluto-private/vds-lines';
import { Body } from '@soluto-private/vds-typography';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ButtonWrapper } from '../../core-ui';

type GuideCardProps = {
    title: string;
    onClick: () => void;
    actionId: string;
    titleSize?: string;
    subtitleSize?: string;
    body: string;
    backgroundColor?: string;
    className?: string;
};

const GuideCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 12.5rem;
    padding: 2rem;
`;

const BodyContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 1rem;
`;

const StyledLine = styled(Line)`
    margin-top: 0.75rem;
`;

const StyledButtonWrapper = styled(ButtonWrapper)`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 700;
    margin-top: 1rem;
    width: 100%;
    font-size: 0.813rem;
`;

const ButtonTextContainer = styled.div`
    margin-right: 0.5rem;
`;

/*
    Note: this style is added to force title text
    to be displayed in 2 lines
*/
const StyledTitle = styled.div`
    max-width: 1rem;
`;

export const GuideCard: FC<GuideCardProps> = ({
    onClick,
    title,
    titleSize,
    body,
    actionId,
    className,
}) => {
    const { t } = useTranslation();

    // TODO: title and body translations are from Contentful

    return (
        <GuideCardContainer className={className}>
            <StyledTitle>
                <Body bold size={titleSize ?? 'large'}>
                    {title}
                </Body>
            </StyledTitle>
            <StyledLine type="light" />
            <BodyContainer>
                {body && <Body size="small">{body}</Body>}
                <StyledButtonWrapper onClick={onClick} actionId={actionId}>
                    <ButtonTextContainer>
                        <Body bold={true}>
                            {t('section.guides.startGuide')}
                        </Body>
                    </ButtonTextContainer>
                    <Icon name="caret-right" size="extraSmall" />
                </StyledButtonWrapper>
            </BodyContainer>
        </GuideCardContainer>
    );
};
