import { Body, Title } from '@soluto-private/vds-typography';
import { FC } from 'react';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';
import { Button } from '../../core-ui';
import UserQuestion from './user-question.svg';

const ExpertChatCTACard = styled.div`
    display: flex;
    flex-direction: row!;
    background-color: ${({ theme }) => theme.colors.greys.light};
    border-radius: 0.5em;
    padding: 1em;
`;

const LeftColumn = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;

const RightColumn = styled.div`
    display: flex;
    flex-direction: column;
`;

const BodyContainer = styled.div`
    margin: 0.5em 0 1em 0;
`;

export const ExpertChatCTA: FC = () => {
    const { t } = useTranslation();

    return (
        <ExpertChatCTACard>
            <LeftColumn>
                <Title size="medium">{t('chat.title')}</Title>
                <BodyContainer>
                    <Body>{t('chat.body')}</Body>
                </BodyContainer>
                <Button
                    actionId="FeelingStuckExpertChatCTA"
                    size="small"
                    href={process.env.REACT_APP_CHAT_SMART_LINK}
                >
                    {t('chat.chatNow')}
                </Button>
            </LeftColumn>
            <RightColumn>
                <img src={UserQuestion} alt="Chat with an expert" />
            </RightColumn>
        </ExpertChatCTACard>
    );
};
