import isPropValid from '@emotion/is-prop-valid';
import { ShouldForwardProp, StyleSheetManager } from 'styled-components';

// This implements the default behavior from styled-components v5
export const shouldForwardProp: ShouldForwardProp<'web'> = (
    propName,
    target
) => {
    if (typeof target === 'string') {
        // For HTML elements, forward the prop if it is a valid HTML attribute
        return isPropValid(propName);
    }
    // For other elements, forward all props
    return true;
};

export const withStyleSheetManager =
    <P extends {}>(Wrapped: React.ComponentType<P>): React.FC<P> =>
    (props: P) =>
        (
            <StyleSheetManager shouldForwardProp={shouldForwardProp}>
                <Wrapped {...props} />
            </StyleSheetManager>
        );
