import { Title } from '@soluto-private/vds-typography';
import { Entry, EntryFieldTypes } from 'contentful';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAnalytics } from 'react-shisell';
import styled from 'styled-components';
import { useSecurityContent } from '../../contentful';
import { QuestionAccordion } from './QuestionAccordion';

export type Question = {
    contentTypeId: 'securityQuestion';
    fields: {
        id: EntryFieldTypes.Symbol;
        title: EntryFieldTypes.Symbol;
        description: EntryFieldTypes.RichText;
    };
};

export type QuestionEntry = Entry<
    Question,
    'WITHOUT_UNRESOLVABLE_LINKS',
    string
>;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin: 2rem;
`;

const WrappedTitle = styled.div`
    margin-bottom: 1rem;
`;

export const CommonQuestions: FC = () => {
    const { t } = useTranslation();

    const { value } = useSecurityContent<Question>({
        contentType: 'securityQuestion',
    });
    const randomizedQuestions = useMemo(
        () => value?.items.sort(() => Math.random() - 0.5) ?? [],
        [value]
    );
    const { dispatcher } = useAnalytics();

    const onClick = (id: string) =>
        dispatcher.dispatch('onClick', {
            ExtraData: {
                ActionId: 'ViewSecurityQuestionDescription',
                QuestionId: id,
            },
        });

    return (
        <Container>
            <WrappedTitle>
                <Title size="medium">
                    {t('section.commonQuestions.title')}
                </Title>
            </WrappedTitle>
            <QuestionAccordion
                questions={randomizedQuestions}
                onClick={onClick}
            />
        </Container>
    );
};
