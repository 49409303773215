import { FC, useEffect } from 'react';

import { Line } from '@soluto-private/vds-lines';
import { Title } from '@soluto-private/vds-typography';
import { RouteChildrenProps } from 'react-router-dom';

import { Document } from '@contentful/rich-text-types';
import { Loader } from '@soluto-private/vds-loaders';
import { useTranslation } from 'react-i18next';
import { useAnalytics } from 'react-shisell';
import styled from 'styled-components';
import { useSecurityContent } from '../../../contentful';
import PageLayout from '../../../core-ui/PageLayout/PageLayout';
import { RoutePath } from '../../../navigation';
import { Breadcrumbs } from '../../../navigation/components';
import { GuideSkeleton, GuideSummary } from '../../types';
import { GuidePaginatedList } from './GuidePaginatedList';

const GuidePageHeaderContainer = styled.div`
    padding: 0 2rem;
`;

const StyledLine = styled(Line)`
    margin: 0.75rem 0 0 0;
`;

type GuidePageHeaderProps = {
    guideName?: string;
    guideSummaryTitle?: string;
};

export const GuidePageHeader: FC<GuidePageHeaderProps> = ({
    guideName,
    guideSummaryTitle,
}) => {
    const { t } = useTranslation();

    return (
        <GuidePageHeaderContainer>
            <Breadcrumbs
                breadcrumbs={[
                    {
                        text: t('page.guides.title'),
                        path: RoutePath.Guides,
                    },
                    {
                        text: guideSummaryTitle || '',
                    },
                ]}
                margin="0 2rem 2rem 0"
            />
            <Title size="large">{guideName}</Title>
            <StyledLine />
        </GuidePageHeaderContainer>
    );
};

export type GuidePageProps = {
    id: string;
};

export const GuidePage: FC<RouteChildrenProps<GuidePageProps>> = ({
    match,
}) => {
    const { id } = match?.params || {};

    const { value: guideSummaryValue } = useSecurityContent<GuideSummary>({
        contentType: 'securityGuideSummary',
        additionalParams: {
            links_to_entry: id,
        },
    });

    const { value, loading } = useSecurityContent<GuideSkeleton>({
        contentType: 'securityGuide',
        additionalParams: {
            'sys.id': id,
        },
    });

    const guide = value?.items[0];
    const guideSummary = guideSummaryValue?.items[0];
    const shouldDisplayPage = !loading && !!guide && !!guideSummary;

    const { dispatcher } = useAnalytics();

    useEffect(() => {
        if (!guide) {
            return;
        }

        dispatcher.dispatch('ViewGuide', {
            ExtraData: {
                GuideId: guide.sys.id,
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [guide]);

    // TODO: guide translations are from Contentful

    return (
        <>
            <Loader active={!shouldDisplayPage} />
            {shouldDisplayPage && (
                <PageLayout
                    margin="1.5rem 0rem 7rem 0rem"
                    pageName="GuidePage"
                    header={
                        <GuidePageHeader
                            guideName={guide.fields.guideOverviewTitle}
                            guideSummaryTitle={guideSummary.fields.title}
                        />
                    }
                    body={
                        <GuidePaginatedList
                            guideSteps={guide.fields.guideSteps}
                            guideOverview={
                                guide.fields
                                    .guideOverviewDescription as Document
                            }
                        />
                    }
                />
            )}
        </>
    );
};
