import { RoutePath } from '../navigation';
import { Category } from './security-center-ui/Categories';

// NOTE: Kept title because it is used as extraData in analytics
export const categories: Category[] = [
    {
        i18nKey: 'section.articles.categories.accountSecurity',
        title: 'Account\nSecurity',
        url: `${RoutePath.Category}/accountSecurity`,
    },
    {
        i18nKey: 'section.articles.categories.bestPractices',
        title: 'Best\nPractices',
        url: `${RoutePath.Category}/bestPractices`,
    },
    {
        i18nKey: 'section.articles.categories.fraudsAndScams',
        title: 'Frauds &\nScams',
        url: `${RoutePath.Category}/fraudsAndScams`,
    },
    {
        i18nKey: 'section.articles.categories.networkSafety',
        title: 'Network\nSafety',
        url: `${RoutePath.Category}/networkSafety`,
    },
    {
        i18nKey: 'section.articles.categories.identityProtection',
        title: 'Identity\nProtection',
        url: `${RoutePath.Category}/identityProtection`,
    },
];

export const pageSectionTabs = [
    { i18nKey: 'section.guides.tab', name: 'guides' },
    {
        i18nKey: 'section.commonQuestions.tab',
        name: 'common-questions',
    },
    {
        i18nKey: 'section.todaysTip.tab',
        name: 'todays-tip',
    },
    {
        i18nKey: 'section.articles.tab',
        name: 'articles',
    },
    { i18nKey: 'section.videos.tab', name: 'videos' },
    {
        i18nKey: 'section.didYouKnow.tab',
        name: 'did-you-know',
    },
];

export enum SectionNames {
    Guides = 0,
    CommonQuestions = 1,
    TodaysTip = 2,
    Articles = 3,
    Videos = 4,
    DidYouKnow = 5,
}
