import { createContext, useContext } from 'react';
import i18n from '../i18n';
import {
    ContentfulSupportedLocale,
    I18N_TO_CONTENTFUL_LOCALE,
    I18nSupportedLocale,
    I18nSupportedLocales,
} from '../types';

export const getContentfulLocale = (
    resolvedLanguage: string | undefined
): ContentfulSupportedLocale => {
    const language = (resolvedLanguage ?? 'en') as I18nSupportedLocale;

    // Catch an edge-case if resolvedLanguage returns
    // a value that is not yet officially supported.
    if (!I18nSupportedLocales.includes(language)) {
        // Default to English
        return I18N_TO_CONTENTFUL_LOCALE.en;
    }

    return I18N_TO_CONTENTFUL_LOCALE[language];
};

export const LanguageContext = createContext<ContentfulSupportedLocale>(
    getContentfulLocale(i18n.resolvedLanguage)
);

export const useLanguage = () => useContext(LanguageContext);
