import { Body, Title } from '@soluto-private/vds-typography';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteChildrenProps } from 'react-router-dom';
import styled from 'styled-components';
import { ArticleSection } from '../articles/components/ArticleSection';
import { PageLayout } from '../core-ui';
import { RoutePath } from '../navigation';
import { VideoSection } from '../video';
import { categories, Category, CategoryTags } from './config';

type HeaderProps = {
    category: Category;
};

type SelectedCategoryPageProps = {
    category: Category;
};

const TitleContainer = styled.div`
    margin: 1.5rem 2rem 0 2rem;
`;

const SectionTitleContainer = styled.div`
    margin: 1.5rem 2rem 0 2rem;
`;

const VideoTitleContainer = styled(SectionTitleContainer)`
    margin-bottom: 1rem;
`;

const WrappedBody = styled.div`
    margin-top: 0.75rem;
`;

const GreySection = styled.div`
    height: 0.75rem;
    margin-top: 0.5rem;
    background-color: ${({ theme }) => theme.colors.greys.light};
`;

const Header: FC<HeaderProps> = ({ category }) => {
    const { t } = useTranslation();

    return (
        <TitleContainer>
            <Title size="large">{t(`page.categories.title.${category}`)}</Title>
            <WrappedBody>
                <Body size="small">
                    {t(`page.categories.description.${category}`)}
                </Body>
            </WrappedBody>
        </TitleContainer>
    );
};

export const SelectedCategoryPage: FC<
    RouteChildrenProps<SelectedCategoryPageProps>
> = ({ match }) => {
    const { t } = useTranslation();

    const { category = 'bestPractices' } = match?.params || {};
    const formattedCategory = categories.includes(category)
        ? category
        : 'bestPractices';

    // TODO: article and video translations are from Contentful

    return (
        <PageLayout
            pageName={formattedCategory}
            breadcrumbs={[
                { text: t('securityCenter'), path: RoutePath.SecurityCenter },
                { text: t(`page.categories.title.${category}`) },
            ]}
            breadcrumbMargin="1.5rem 0 0 2rem"
            header={<Header category={formattedCategory} />}
            margin="0"
            body={
                <>
                    <SectionTitleContainer>
                        <Title size="medium">
                            {t('page.categories.articles')}
                        </Title>
                    </SectionTitleContainer>
                    <ArticleSection
                        tags={[
                            CategoryTags[formattedCategory],
                            CategoryTags.securityAdvisor,
                        ]}
                    ></ArticleSection>
                    <GreySection />
                    <VideoTitleContainer>
                        <Title size="medium">
                            {t('page.categories.videos')}
                        </Title>
                    </VideoTitleContainer>
                    <VideoSection
                        categoryTag={CategoryTags[formattedCategory]}
                    />
                </>
            }
        />
    );
};
