import { Line } from '@soluto-private/vds-lines';
import { Loader } from '@soluto-private/vds-loaders';
import { Body, Title } from '@soluto-private/vds-typography';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button, LinkList, PageLayout } from '../../../core-ui';
import { RoutePath } from '../../../navigation';
import { formatChatSessionDate } from '../../formatChatSessionDate';
import { useChatSessions } from '../../hooks';

const TitleContainer = styled.div`
    margin-bottom: 0.5rem;
`;

const StyledLine = styled(Line)`
    margin: 0 0 1.5em 0;
`;

const CenteredContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 4rem;
`;

const StyledButton = styled(Button)`
    margin: 1.5rem 0;
`;

const ChatHistoryHeader: FC = () => {
    const { t } = useTranslation();

    return (
        <>
            <TitleContainer>
                <Title size="large">{t('page.chatHistory.title')}</Title>
            </TitleContainer>
            <Body>{t('page.chatHistory.body')}</Body>
        </>
    );
};

// NOTE: Page is actually not currently working with useChatSessions.
// TODO: Confirm if this page is still being used or can be deleted.

const ChatHistoryBody: FC = () => {
    const { t } = useTranslation();

    const chatSessions = useChatSessions();

    return !chatSessions ? (
        <Loader active={true} />
    ) : (
        <div>
            <StyledLine />
            {chatSessions.length === 0 && (
                <>
                    <Title size="medium">
                        {t('page.chatHistory.notFound')}
                    </Title>
                    <CenteredContainer>
                        <Body>{t('page.chatHistory.stillNeedHelp')}</Body>
                        <StyledButton
                            actionId="StartChatButton"
                            href={process.env.REACT_APP_CHAT_SMART_LINK}
                        >
                            {t('page.chatHistory.startChat')}
                        </StyledButton>
                    </CenteredContainer>
                </>
            )}
            <LinkList
                items={chatSessions.map((s) => ({
                    actionId: 'ViewChatSession',
                    extraData: { ChatSessionId: s.id },
                    text: formatChatSessionDate(s.creationTime),
                    path: `${RoutePath.ChatHistory}/${s.id}`,
                }))}
            />
        </div>
    );
};

export const ChatHistoryPage: FC = () => {
    const { t } = useTranslation();

    return (
        <PageLayout
            pageName="ChatHistoryPage"
            breadcrumbs={[
                { text: t('securityAdvisor'), path: RoutePath.Home },
                { text: t('page.chatHistory.title') },
            ]}
            header={<ChatHistoryHeader />}
            body={<ChatHistoryBody />}
        />
    );
};
