import {
    Link as RouterLink,
    LinkProps as RouterLinkProps,
} from 'react-router-dom';
import { enrichAnalytics, withAnalyticOnEvent } from 'react-shisell';
import { withExtras } from 'shisell/extenders';

type LinkProps = {
    actionId: string;
    extraData?: Record<string, unknown>;
} & RouterLinkProps;

const Link = withAnalyticOnEvent<'onClick', [], RouterLinkProps>({
    eventName: 'onClick',
    analyticName: 'Click',
})(RouterLink);

// Remove actionId and extraData after updating the dispatcher.
// This resolves React DOM warnings complaining about custom props.
const LinkWrapper: React.FC<LinkProps> = ({
    actionId,
    extraData,
    ...props
}) => <Link {...props} />;

export default enrichAnalytics<LinkProps>(
    (dispatcher, { actionId, extraData }) =>
        dispatcher.extend(
            withExtras({
                ActionId: actionId,
                ...extraData,
            })
        )
)(LinkWrapper);
