import { Line } from '@soluto-private/vds-lines';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Link, LinkList, LinkListItemProps } from '../../core-ui';
import { RoutePath } from '../../navigation';
import { Article } from '../Article';
import { useArticles } from '../hooks';
import { formatArticles } from './utils';

type ArticleSectionProps = {
    tags: string[];
    limit?: number;
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 2rem;
`;

const StyledLine = styled(Line)`
    margin: 1rem 0 1.5rem 0;
`;

const StyledLink = styled(Link)`
    && {
        margin: 1.5rem 0 2rem 0;
        width: fit-content;
    }
`;

export const ArticleSection: FC<ArticleSectionProps> = ({ tags, limit }) => {
    const { t } = useTranslation();

    const { value } = useArticles<Article>({
        limit,
        additionalParams: { 'metadata.tags.sys.id[all]': tags.toString() },
    });

    const formattedArticles = useMemo<LinkListItemProps[] | undefined>(
        () => formatArticles(value, tags),
        [value, tags]
    );

    // TODO: articles translations are from Contentful

    return (
        <Container>
            <StyledLine type="light" />
            <LinkList items={formattedArticles ?? []} displayBottomBorder />
            {formattedArticles && limit && (
                <StyledLink to={RoutePath.Articles} actionId="ViewMoreArticles">
                    {t('section.articles.viewAll')}
                </StyledLink>
            )}
        </Container>
    );
};
