import { Body, Title } from '@soluto-private/vds-typography';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { DailytaskEntry } from '../../types';
import { MarkAsHelpful } from './MarkAsHelpful';

const WrappedBody = styled.div`
    margin: 0.5rem 0 1rem 0;
`;

type CompletedTipProps = {
    tip: DailytaskEntry;
};

export const CompletedTip: FC<CompletedTipProps> = ({ tip }) => {
    const { t } = useTranslation();

    return (
        <>
            <Title>{t('page.todaysTip.completed.title')}</Title>
            <WrappedBody>
                <Body>{t('page.todaysTip.completed.body')}</Body>
                <MarkAsHelpful
                    id={`tip-${tip.fields.id}`}
                    extraData={{ TipId: tip.fields.id, TipSysId: tip.sys.id }}
                    text={t('page.todaysTip.completed.wasThisHelpful')}
                />
            </WrappedBody>
        </>
    );
};
