import { Line } from '@soluto-private/vds-lines';
import { Body, Title } from '@soluto-private/vds-typography';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAnalytics } from 'react-shisell';
import styled from 'styled-components';
import { PageLayout } from '../../core-ui';
import { RoutePath } from '../../navigation';
import { useTodaysTip } from '../useTodaysTip';
import { CompletedTip, UncompletedTip } from './index';

const TitleContainer = styled.div`
    margin-top: 1.5rem;
`;
const WrappedBody = styled.div`
    margin-top: 0.75rem;
`;

const WrappedLine = styled(Line)`
    margin-top: 0.75rem;
`;

const Header: FC = () => {
    const { t } = useTranslation();

    return (
        <TitleContainer>
            <Title size="large">{t('page.todaysTip.title')}</Title>
            <WrappedBody>
                <Body size="small">{t('page.todaysTip.body')}</Body>
            </WrappedBody>
            <WrappedLine type="heavy"></WrappedLine>
        </TitleContainer>
    );
};

const TipSection: FC = () => {
    const { todaysTip, isTipCompleted, setTipCompleted } = useTodaysTip();

    const { dispatcher } = useAnalytics();

    useEffect(() => {
        if (!todaysTip || isTipCompleted) {
            return;
        }

        dispatcher.dispatch('ViewTodaysTip', {
            ExtraData: {
                TodaysTipId: todaysTip.sys.id,
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [todaysTip, isTipCompleted]);

    return (
        <>
            {todaysTip && isTipCompleted ? (
                <CompletedTip tip={todaysTip} />
            ) : (
                todaysTip && (
                    <UncompletedTip
                        tip={todaysTip}
                        onClick={() => setTipCompleted()}
                    />
                )
            )}
        </>
    );
};

export const TodaysTipPage: FC = () => {
    const { t } = useTranslation();

    return (
        <PageLayout
            pageName="TodaysTip"
            header={<Header />}
            breadcrumbs={[
                { text: t('securityCenter'), path: RoutePath.SecurityCenter },
                { text: t('page.todaysTip.title') },
            ]}
            body={<TipSection />}
        />
    );
};
