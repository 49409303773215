import { Line } from '@soluto-private/vds-lines';
import { Loader } from '@soluto-private/vds-loaders';
import { Body } from '@soluto-private/vds-typography';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteChildrenProps } from 'react-router-dom';
import styled from 'styled-components';
import { Button, PageLayout } from '../../../core-ui';
import { RoutePath } from '../../../navigation';
import { useChatSessionMessages } from '../../hooks';
import { ChatLine } from './ChatLine';

const StyledLine = styled(Line)`
    margin: 2rem 0 1rem 0;
`;

const CenteredContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const StyledButton = styled(Button)`
    margin: 1.5rem 0;
`;

type ChatHistorySessionProps = {
    id?: string;
};

// NOTE: Page is actually not currently working with useChatSessionMessages.
// TODO: Confirm if this page is still being used or can be deleted.

const ChatHistorySessionBody: FC<ChatHistorySessionProps> = ({ id }) => {
    const { t } = useTranslation();

    const messages = useChatSessionMessages(id);

    return !messages ? (
        <Loader active={true} />
    ) : (
        <div>
            {messages.map((m, i, ar) => (
                <ChatLine
                    key={`ChatLine-${m.id}`}
                    message={m}
                    isUser={m.sender === ar[0].sender}
                    displaySender={i === 0 || m.sender !== ar[i - 1].sender}
                />
            ))}
            <StyledLine />
            <CenteredContainer>
                <Body>{t('page.chatHistory.stillNeedHelp')}</Body>
                <StyledButton
                    actionId="StartChatButton"
                    href={process.env.REACT_APP_CHAT_SMART_LINK}
                >
                    {t('page.chatHistory.startChat')}
                </StyledButton>
            </CenteredContainer>
        </div>
    );
};

export const ChatHistorySessionPage: FC<
    RouteChildrenProps<ChatHistorySessionProps>
> = ({ match }) => {
    const { t } = useTranslation();

    const { id } = match?.params || {};

    return (
        <PageLayout
            pageName={`ChatHistorySessionPage-${id}`}
            breadcrumbs={[
                {
                    text: t('page.chatHistory.title'),
                    path: RoutePath.ChatHistory,
                },
                { text: t('page.chatHistory.session.title') },
            ]}
            body={<ChatHistorySessionBody id={id} />}
            margin="1.5rem 1rem"
        />
    );
};
