import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Document } from '@contentful/rich-text-types';
import {
    Accordion,
    AccordionDetail,
    AccordionHeader,
    AccordionItem,
    AccordionTitle,
} from '@soluto-private/vds-accordions';
import { FC } from 'react';
import styled from 'styled-components';
import { QuestionEntry } from './CommonQuestions';

const WrappedAccordionDetail = styled(AccordionDetail)`
    div div span p {
        margin: 0;
    }
`;

export const QuestionAccordion: FC<{
    questions: QuestionEntry[];
    onClick?: (id: string) => void;
}> = ({ questions, onClick }) => (
    // TODO: title and description translations are from Contentful
    <Accordion type="single">
        {questions.map(({ fields: { title, description, id }, sys }) => (
            <AccordionItem key={id}>
                <AccordionHeader onClick={() => onClick?.(sys.id)}>
                    <AccordionTitle>{title}</AccordionTitle>
                </AccordionHeader>
                <WrappedAccordionDetail>
                    {documentToReactComponents(description as Document)}
                </WrappedAccordionDetail>
            </AccordionItem>
        ))}
    </Accordion>
);
