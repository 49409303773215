export const categories = [
    'accountSecurity',
    'bestPractices',
    'fraudsAndScams',
    'networkSafety',
    'identityProtection',
] as const;
export type Category = (typeof categories)[number];

// TODO: can be deleted in favor of i18n
export enum CategoryDescription {
    accountSecurity = 'Find safe and reliable ways to keep all of your online accounts safe.',
    networkSafety = 'Wi-Fi, Wi-Fi security and web security ',
    fraudsAndScams = 'From social engineering to phising and all the things in between. ',
    identityProtection = 'Protect your data from identity theft and master online privacy and protection.',
    bestPractices = 'Cybersecurity, secure travel and general best practices to protect your information.',
}

// TODO: can be deleted in favor of i18n
export enum CategoryTitle {
    accountSecurity = 'Account security',
    networkSafety = 'Network safety',
    fraudsAndScams = 'Frauds and scams',
    identityProtection = 'Identity protection',
    bestPractices = 'Best practices',
}

export enum CategoryTags {
    securityAdvisor = 'vzSecurityAdvisor',
    accountSecurity = 'vzAccountSecurity',
    networkSafety = 'vzNetworkSafety',
    fraudsAndScams = 'vzFraudsandScams',
    identityProtection = 'vzIdentityProtection',
    bestPractices = 'vzBestPractices2',
    popular = 'vzPopularArticle',
}
