import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { useAnalytics } from 'react-shisell';
import styled from 'styled-components';
import { theme } from '../../core-ui/Theme/theme';
import { GuideSummaryEntryCollection } from '../../guides/types/GuideSummary';
import { GuideCard } from './GuideCard';

const StyledGuideCard = styled(GuideCard)`
    border-bottom: solid 0.063rem ${theme.colors.greys.main};
`;

const CardGridContainer = styled.div`
    border-top: solid 0.063rem ${theme.colors.greys.main};
    display: grid;
    grid-template-columns: 50% 50%;
    & ${StyledGuideCard}:nth-child(odd) {
        border-right: solid 0.063rem ${theme.colors.greys.main};
    }
`;

export const GuideCardGrid: FC<{
    guideCardsContent: GuideSummaryEntryCollection;
}> = ({ guideCardsContent }) => {
    const { dispatcher } = useAnalytics();
    const history = useHistory();

    return (
        <CardGridContainer>
            {guideCardsContent.items.map(
                (
                    { fields: { title, description, relatedGuideLink } },
                    index
                ) => (
                    // TODO: When implementing Contentful translations,
                    // figure out how to keep actionId as original title?
                    // (not translated to maintain consistency with analytics?)
                    <StyledGuideCard
                        key={`${index}-${title}`}
                        title={title}
                        body={description}
                        actionId={title}
                        onClick={() => {
                            history.push({
                                pathname: `/guides/${relatedGuideLink?.sys.id}`,
                            });
                            dispatcher.dispatch('onClick', {
                                ExtraData: {
                                    ActionId: 'RecommendedGuideCard',
                                },
                            });
                        }}
                    />
                )
            )}
        </CardGridContainer>
    );
};
