import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { ActionCard } from '../../core-ui/ActionCard';
import { RoutePath } from '../../navigation';

const TodaysTipCardContainer = styled.div`
    min-height: 7.75rem;
    border: solid ${({ theme }) => theme.colors.secondary.black};
    border-width: 2rem 0.5rem;
`;

const StyledActionCard = styled(ActionCard)`
    padding: 2rem;
`;

export const TodaysTipCard: FC = () => {
    const { t } = useTranslation();

    const history = useHistory();

    return (
        <TodaysTipCardContainer>
            <StyledActionCard
                title={t('section.todaysTip.title')}
                body={t('section.todaysTip.body')}
                bodyMargin="0.5rem 0 0 0"
                onClick={() => {
                    history.push(RoutePath.TodaysTip);
                }}
                actionId="TodaysTipCard"
            ></StyledActionCard>
        </TodaysTipCardContainer>
    );
};
