import { Composer } from '@soluto-private/content-component-library';
import { Body, Micro } from '@soluto-private/vds-typography';
import Vimeo from '@u-wave/react-vimeo';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAnalytics } from 'react-shisell';
import styled from 'styled-components';
import { VendorVideo } from '../types';

const TextContainer = styled.div`
    margin: 1rem 2rem;
`;

const VimeoContainer = styled.div`
    margin: 0 8%;
    @media (max-width: 768px) {
        margin: 0;
    }
`;

const VideoPlayer: FC<{ video: VendorVideo }> = ({ video }) => {
    const { t } = useTranslation();

    const videoId = video.fields.brightcoveVideoId;
    const { dispatcher } = useAnalytics();

    return (
        <>
            <Composer vendor="verizon">
                <VimeoContainer>
                    <Vimeo
                        key={videoId}
                        video={videoId}
                        width={640}
                        height={480}
                        responsive={true}
                        onPlay={() => {
                            dispatcher.dispatch('PlayVideo', {
                                ExtraData: {
                                    VideoId: video.sys.id,
                                },
                            });
                        }}
                    />
                </VimeoContainer>
            </Composer>
            <TextContainer>
                <Micro>{t('section.videos.playing')}</Micro>
                <Body bold size="large">
                    {video.fields.name}
                </Body>
            </TextContainer>
        </>
    );
};

export default VideoPlayer;
