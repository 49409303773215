import { ContentfulResponse } from '@soluto-private/content-component-library';
import { useAsync } from 'react-use';
import { ArticleCategoryEntry } from '../../articles';
import { useLanguage } from '../../i18n';
import { contentfulClientArticle } from './createContentfulClientArticle';

const VENDORS = [
    'verizon',
    'staples',
    'sprint',
    'att',
    'cricket',
    'asurion',
    'agnostic',
];

const removeVendors = (categories: ArticleCategoryEntry[]) =>
    categories.filter((category: ArticleCategoryEntry) =>
        category.fields
            ? !VENDORS.includes(category.fields.articleCategory.toLowerCase())
            : null
    );

const buildArticleQuery = (override: {}): {} => {
    const base = {
        limit: 1,
        skip: 0,
        include: 10,
        content_type: 'article',
    };
    return { ...base, ...override };
};

export const useContentfulProductArticle = (articleId: string) => {
    const locale = useLanguage();

    return useAsync(async () => {
        const response = await contentfulClientArticle.getEntries(
            buildArticleQuery({
                'sys.id': articleId,
                locale,
            })
        );
        if (!response || response.items.length === 0) {
            throw new Error('Could not get article response from Contentful');
        }

        const body = response.items[0];

        // Apply vendor stripping logic
        if (body.fields.category) {
            body.fields.category = removeVendors(
                body.fields.category as ArticleCategoryEntry[]
            );
        }

        return body as unknown as ContentfulResponse;
    }, [contentfulClientArticle, locale]);
};
