import { ContentfulClientApi, EntrySkeletonType } from 'contentful';
import { useAsyncRetry } from 'react-use';
import { useErrorHandling } from '../error-handling';
import { useLanguage } from '../i18n';

export type ContentParams = {
    contentType: string;
    limit?: number;
    additionalParams?: {};
    isRequiredContent?: boolean;
};
export const createUseContentfulContent = (
    useClient: () => ContentfulClientApi<'WITHOUT_UNRESOLVABLE_LINKS'>
) =>
    function useContentfulContent<T extends EntrySkeletonType>({
        contentType,
        limit,
        additionalParams,
        isRequiredContent = true,
    }: ContentParams) {
        const client = useClient();
        const locale = useLanguage();

        const state = useAsyncRetry(async () => {
            const entries = await client.getEntries<T>({
                content_type: contentType,
                limit,
                ...additionalParams,
                locale,
            });
            return entries;
        }, [client, locale]);

        useErrorHandling({
            state,
            message: `Failed to fetch ${contentType} from contentful`,
            showErrorPage: isRequiredContent,
        });

        return state;
    };
