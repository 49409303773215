export const I18nSupportedLocales = ['en', 'es'] as const;

export type I18nSupportedLocale = (typeof I18nSupportedLocales)[number];

export const ContentfulSupportedLocales = ['en-US', 'es-US'] as const;

export type ContentfulSupportedLocale =
    (typeof ContentfulSupportedLocales)[number];

export const I18N_TO_CONTENTFUL_LOCALE: Record<
    I18nSupportedLocale,
    ContentfulSupportedLocale
> = {
    en: 'en-US',
    es: 'es-US',
} as const;
