import { Body, Micro } from '@soluto-private/vds-typography';
import { FC } from 'react';
import styled from 'styled-components';
import { theme } from '../../core-ui/Theme/theme';

import { VendorVideo } from '../types';

const VideoListContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 1.25rem;
`;

const VideoItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0.75rem 0;
`;

const formatVideoDuration = (duration: number = 0) => {
    if (duration) {
        const formattedDuration = `${Math.floor(duration / 60)}:${(
            duration % 60
        ).toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false,
        })}`;
        return (
            <Micro color={theme.colors.greys.dark}>{formattedDuration}</Micro>
        );
    }
    return <div>&nbsp;</div>;
};

type VideoListProps = {
    videos: VendorVideo[];
    selectedVideoId: string;
    setSelectedVideo: (video: VendorVideo) => void;
};

export const VideoList: FC<VideoListProps> = ({
    videos,
    selectedVideoId,
    setSelectedVideo,
}) => {
    const videosExcludingSelected = videos?.filter(
        (v) => v.sys.id !== selectedVideoId
    );

    return (
        <VideoListContainer>
            {videosExcludingSelected.map((video, i) => (
                <VideoItemContainer
                    key={`video-${video.sys.id}-${i}`}
                    onClick={() => setSelectedVideo(video)}
                >
                    <Body bold>{video.fields.name}</Body>
                    {formatVideoDuration(video.fields.duration)}
                </VideoItemContainer>
            ))}
        </VideoListContainer>
    );
};
