import { Body } from '@soluto-private/vds-typography';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { ActionCard, SideScroll } from '../../core-ui';
import { categories } from '../config';

export type Category = {
    i18nKey: string;
    title: string;
    url: string;
};

const StyledActionCard = styled(ActionCard)`
    flex: 0 0 auto;
    margin-right: 0.5rem;
    min-width: 9.375rem;
    padding: 0.75rem;
    white-space: pre;
    background-color: ${(props) => props.theme.colors.greys.light};
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const WrappedSubtitle = styled.div`
    margin: 0.75rem 0 1rem 2rem;
`;

const Categories: FC = () => {
    const { t } = useTranslation();

    const history = useHistory();
    const handleClick = (path: string = '/') => {
        history.push(path);
    };

    return (
        <SideScroll
            component={
                <>
                    {categories.map(({ i18nKey, title, url }, index) => (
                        <StyledActionCard
                            body={t(i18nKey)}
                            bodyBold={true}
                            key={`title-${index}`}
                            onClick={() => handleClick(url)}
                            actionId="Category"
                            extraData={{ Category: title }}
                            iconSize="small"
                        ></StyledActionCard>
                    ))}
                </>
            }
        ></SideScroll>
    );
};

export const CategoryContainer: FC = () => {
    const { t } = useTranslation();

    return (
        <Container>
            <WrappedSubtitle>
                <Body viewport="mobile" primitive="h6">
                    {t('section.articles.categories.title')}
                </Body>
            </WrappedSubtitle>

            <Categories />
        </Container>
    );
};
